import React from "react"
import Layout from "../components/layout"
import styled from 'styled-components'
import { Link } from "gatsby"

const BackButton = styled.p`
    transition: 0.2s linear; 

    :hover& {
        color: rgb(255, 87, 34)
    }
`

const FormRedirect = props => {

    return ( 
        <Layout
            title = { 'Message Received' }
        >
            <div className="site-About">
                <div 
                    style = {{
                        width: '100vw',
                        minHeight : '90vh',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        paddingTop: '5vh'
                    }}
                >
                    <h3 style = {{ color : '#009688' }}>We received your message</h3>
                    <br/>
                    <br/>
                    <p>We will response in 24 hours, excluding holidays, Thank you!</p>
                    <Link to = '/contact' ><BackButton>Click here to go back</BackButton></Link>
                </div>
            </div>
        </Layout>
    )
}
export default FormRedirect


